import React from 'react'
import { Props } from "./AnalyticsTopSection"

function CustomPagination({data, setData}: Props) {

    const page = Number(data.main.page);
    const perPage = Number(data.main.perPage);

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, [name]: value, page: 1}
          });
    }

    const gotoPreviousPage = () => {
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, page: page - 1}
          });
    }

    const gotoNextPage = () => {
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, page: page + 1}
          });
    }

  return (
     <div className="card">

				<div className="card-footer d-flex justify-content-between align-items-center">
                <div className="form-group m-0 template-search-div">  
                          
                            <select className="form-control sort-select" name="perPage" onChange={handleSelectChange} value={perPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>

                        <ul className="pagination pagination-sm m-0 float-right">
						<li className={`${Number(page) <= 1 ? "dont-click" : "you-can-click"} page-item`}>
							<span className="page-link" onClick={gotoPreviousPage}>
								« Previous
							</span>
						</li>
						<li className={`you-can-click page-item`}>
							<span className="page-link" onClick={gotoNextPage}>
								Next »
							</span>
						</li>
					</ul>

				</div>
			</div> 
  )
}

export default CustomPagination