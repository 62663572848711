// import DesignTabHeader from "./DesignTabHeader";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useParams, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import useAuth from "../../auth/useAuth";
import EditTemplate from "../../components/EditTemplate";
import Tag from "../../components/Tag";
import Roller from "../../shared/Roller";
import './Templates.scss';
// import Swal from "sweetalert2";
// import HorizontalScroll from "react-scroll-horizontal";

interface UseParamsProps {
    id: string
}

interface TemplateProps {
    category: {
        name: string, 
        description: string,
        coverImage: string, 
        slug: string
    },
    componentCount: number,
    description: string,
    height: number,
    id: string,
    inputCount: number,
    name: string,
    previewImage: string,
    publishedAt: string,
    stats: {
        views: number, 
        usage: number
    },
    tags: [],
    user: {
        firstName: string, 
        lastName: string, 
        username: string, 
        profilePhoto: string,
        coverPhoto: string,
    },
    width: number
}

const Template = () => {
    let token = localStorage.getItem("inbrAdminToken");
    const auth = useAuth();

    if (token) {
        token = JSON.parse(token);
    }

	const { id } = useParams<UseParamsProps>();
	const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [template, setTemplate] = useState<TemplateProps>({
        category: {
            name: "", 
            description: "",
            coverImage: "", 
            slug: ""
        },
        componentCount: 0,
        description: "",
        height: 0,
        id: "",
        inputCount: 0,
        name: "",
        previewImage: "",
        publishedAt: "",
        stats: {
            views: 0, 
            usage: 0
        },
        tags: [],
        user: {
            firstName: "", 
            lastName: "", 
            username: "", 
            profilePhoto: "",
            coverPhoto: "",
        },
        width: 0
    });
	
	const [categoryNameArr, setCategoryNameArr] = useState<string[]>();
	const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false)
    }

	useEffect(() => {
		console.log(categoryNameArr);
	}, [categoryNameArr]);

    function getTemplate () : void {
		// console.log("starting");
		// console.log(dataFrom, dataTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/templates/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
            setTemplate(result.template);
			// console.log(result);
		})
		.catch(error => {
			setError("Bad network connection: Failed to fetch");
            console.log(error)
			setLoading(false);
		});
	}

    function deleteTemplate () : void {
		setLoading(true);
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/templates/${id}`, requestOptions)
		.then(response => response.json())
		.then(result => {
			setLoading(false);
			// console.log(result);
            if (result.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: result.message,
                }).then(() => history.push('/templates'));
            }
		})
		.catch(error => {
			setError(error);
            Swal.fire({
                icon: "error",
                title: error.error
            })
			setLoading(false);
		});
	}

    useEffect(() => {
        // console.log(template, "template")
        if (Object.entries(template).length > 0) {
            let splitArr;

			if (template.category.name.includes("&amp;")) {
				splitArr = template.category.name.split("&amp;");
				setCategoryNameArr(splitArr);
			}
        }
    }, [template]);

    useEffect(() => {
        getTemplate();

        // eslint-disable-next-line
    }, []);

    if (loading) {
		return <Roller />
	} else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	}

	return (
		<>
            <div>
                <NavLink to="/templates" className="custom-link">&larr; Back to all templates</NavLink>
            </div>
            <div className="row mt-3 mb-5 pb-5">
                <div className="col-md-6">
                    {template.previewImage ? (
                        <img src={template.previewImage} className="preview_img" alt="template preview" />
                    ) : (
                        <div style={{
                            height: "100%",
                            border: "1px solid #ff0066",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                        }}>NO PREVIEW IMAGE</div>
                    )}
                </div>
                <div className="col-md-6">
                    <br />
                    <h1>{template.name.includes("&amp;") ? template.name.replace("&amp;", "&") : template.name}</h1>
                    <div>
                        <img
                            src={template?.user?.profilePhoto !== "" ? template?.user?.profilePhoto : "/assets/img/user.png"}
                            className="owner_img"
                            alt={template.user.firstName}
                        />
                    </div>
                    <p>
                        By{" "}
                        <span>
                            <strong>{`${template.user.firstName} ${template.user.lastName}`}</strong>
                        </span>
                    </p>
                    <div className="template-btn-div">
                        <button className={`btn btn-info btn-md btn-block p-2 ${(auth?.adminProfile?.role === "designer" || auth?.adminProfile?.role === "viewer") && "not-allowed"}`} disabled={(auth?.adminProfile?.role === "designer" || auth?.adminProfile?.role === "viewer") ? true : false } onClick={() => setShowModal(true)}>
                            Edit
                        </button>
                        <button className={`btn btn-danger btn-md btn-block p-2 mt-0 ${(auth?.adminProfile?.role === "designer" || auth?.adminProfile?.role === "viewer") && "not-allowed"}`} disabled={(auth?.adminProfile?.role === "designer" || auth?.adminProfile?.role === "viewer") ? true : false } onClick={deleteTemplate}>
                            Delete
                        </button>
                    </div>
                    <br />
                    <h2>About this template</h2>
                    <div>{template.description.includes("&amp;") ? template.description.replace("&amp;", "&") : template.description}</div>
                </div>
                <div className="col-md-12 mt-3">
                    <div className="template-footer-inner-div" style={{ marginBottom: 5 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d81b60" className="bi bi-compass" viewBox="0 0 16 16">
                            <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                            <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                        </svg>
                        <span className="template-footer-span-label" style={{ marginLeft: "2%" }}>{template.stats.views} {" "}Views</span>
                    </div>
                    <div className="template-footer-inner-div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d81b60" className="bi bi-people" viewBox="0 0 16 16">
                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                        </svg>
                        <span className="template-footer-span-label" style={{ marginLeft: "2%" }}>{template.stats.usage} {" "}Usage</span>
                    </div>
                </div>
                <div className="col-md-12 mt-3 t-categories">
                    {template.tags.map((tag: any, index) => (
                        <Tag key={index} {...tag} />
                    ))}
                </div>
            </div>
            {
                template && (
                    <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>EDIT TEMPLATE</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EditTemplate categoryIdName={template.category.name} name={template.name.includes("&amp;") ? template.name.replace("&amp;", "&") : template.name} description={template.description.includes("&amp;") ? template.description.replace("&amp;", "&") : template.description} tags={template.tags} closeModal={closeModal} id={template.id} getTemplate={getTemplate} />
                        </Modal.Body>
                    </Modal>
                )
            }
        </>
	);
};

export default Template;
