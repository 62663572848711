import { toQueryString } from "./refineData"

let token = localStorage.getItem("inbrAdminToken");
if (token) { token = JSON.parse(token); }

interface WorkflowsParams {
    dateFrom: string, dateTo: string, userId: string, 
    query: string, perPage: number, page: number,
    sortBy: string, status: string, orderBy: string, count?: number
}

const getWorkflows = async (payLoad: WorkflowsParams) => {

    let queryString: string = toQueryString(payLoad);

    // console.log("starting");
    // console.log(dataFrom, dataTo)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/workflows?${queryString}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

const getWorkflowsInstances = async (payLoad: WorkflowsParams, id: string) => {

    let queryString: string = toQueryString(payLoad);

    // console.log("starting");
    // console.log(dataFrom, dataTo)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/workflows/${id}/instances?${queryString}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

const getWorkflowsStats = async (payLoad: WorkflowsParams) => {

    let queryString: string = toQueryString(payLoad);

    // console.log("starting");
    // console.log(dataFrom, dataTo)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/workflows/stats?${queryString}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

const getWorkflow = async (workflowId: string) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/workflows/${workflowId}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

export const workflowsApis = { getWorkflows, getWorkflowsInstances, getWorkflowsStats, getWorkflow }