import React, { useEffect, /* useMemo, */ useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import { AnalyticsParams } from '../../dashboard/Dashboard';
import NotAuthorized from '../../shared/NotAuthorized';
// import PaginationComponent from '../../shared/PaginationComponent';
import Roller from '../../shared/Roller';

interface BillingCycleProps {
    amountPaid: number,
    anchorDay: number,
    automationJobCount: number,
    automationJobQuota: number,
    chargeAttempt: number,
    chargebeeInvoiceId: null
    createdAt: string,
    currency: string,
    emailSendCount: number,
    emailSendQuota: number,
    endedAt: string,
    endsAt: string,
    estimatedCost: number,
    generatedImageCount: number,
    generatedImageQuota: number,
    id: number,
    isActive: boolean,
    isPaid: boolean,
    isRenewing: boolean,
    isWatermarkEnabled: boolean,
    lastChargeAttemptAt: any,
    meta: any,
    paidAt: any,
    plan: string,
    startsAt: string,
    updatedAt: string,
    userId: string
}

const BillingCycles : React.FC = () => {
    let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();
    const [billingCycles, setBillingCycles] = useState<never[]>([]);
    const [backupbillingCycles, setBackupBillingCycles] = useState<never[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    
    const useQuery = () => new URLSearchParams(useLocation().search);
	let query = useQuery();

    if (token) {
        token = JSON.parse(token);
    }

    const auth = useAuth();

    useEffect(() => {
        if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    const justDate = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/")
        );
    };

    function getBillingCycles (dataFrom?: object, dataTo?: object) : void {
        setError("");
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/billing-cycles?dataFrom=${dataFrom}&dataTo=${dataTo}`, requestOptions)
		.then(response => response.json())
		.then(result => {
            setLoading(false);
            // console.log(result);
            
            if (result.billingCycles.length > 0) {
                setBillingCycles(result.billingCycles);
                setBackupBillingCycles(result.billingCycles);
            } else if (result.error === "Invalid Permission") {
                setError("Invalid Permission");
            } else if (result.error) {
                Swal.fire({
                    icon: "error",
                    title: result.error
                })
            }
			// if (range === "Custom date") {
			// 	setAnalyticsCustomDate(result);
			// 	setFetchingCustomDate(false);
			// }
		})
		.catch(error => {
            console.error(error)
            Swal.fire({
                icon: "error",
                title: "Please check your connection and try again"
            })
			setError("Please check your connection and try again");
		});
	}

    // useEffect(() => console.log(billingCycles, "billing cycles"), [billingCycles]);

    const [filterPlanSelect, setFilterPlanSelect] = useState("");
    const [filterBillingCyclesSelect, setFilterBillingCyclesSelect] = useState("");

    const handleFilterByPlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterPlanSelect(e.target.value);
    }

    const handleFilterByBillingCycle = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilterBillingCyclesSelect(e.target.value);
    }

    useEffect(() => {
        // console.log(filterPlanSelect, "filterPlanSelect");
        if (filterPlanSelect !== "") {
            // setFilterPlanSelect("");
            if (filterPlanSelect === "default") {
                setBillingCycles(backupbillingCycles);
            } else {
                const result = backupbillingCycles.filter((cycle: BillingCycleProps) => cycle.plan === filterPlanSelect);
                setBillingCycles(result);
            }            
        }
    }, [filterPlanSelect, backupbillingCycles]);

    useEffect(() => {
        // console.log(filterBillingCyclesSelect, "filterBillingCyclesSelect");
        if (filterBillingCyclesSelect !== "") {
            if (filterBillingCyclesSelect === "default") {
                setBillingCycles(backupbillingCycles);
            } else if (filterBillingCyclesSelect === "overages") {
                const result = backupbillingCycles.filter((cycle: BillingCycleProps) => (cycle.automationJobCount > cycle.automationJobQuota || cycle.generatedImageCount > cycle.generatedImageQuota || cycle.emailSendCount > cycle.emailSendQuota));
                setBillingCycles(result);
            } else if (filterBillingCyclesSelect === "paid") {
                const result = backupbillingCycles.filter((cycle: BillingCycleProps) => cycle.isPaid === true);
                setBillingCycles(result);
            } else if (filterBillingCyclesSelect === "unpaid") {
                const result = backupbillingCycles.filter((cycle: BillingCycleProps) => cycle.isPaid === false);
                setBillingCycles(result);
            }           
        }
    }, [filterBillingCyclesSelect, backupbillingCycles]);

    // const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const [pageNumberLimit] = useState(10);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

    const pages = [];

    for (let i = 1; i <= Math.ceil(billingCycles.length/itemsPerPage); i++) {
        pages.push(i);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = billingCycles.slice(indexOfFirstItem, indexOfLastItem); 

    const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) : void => {
        setCurrentPage(Number(e.currentTarget.id));
    }

    const handlePagePrev = () => {
        setCurrentPage(currentPage - 1);
        if (((currentPage - 1) % pageNumberLimit) === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    const handlePageNext = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }


    let pageDecrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageDecrementBtn = <li onClick={handlePagePrev}> &hellip; </li>
    }

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handlePageNext}> &hellip; </li>
    }

    const renderPageNumbers = pages.map(number => {
        if (number < (maxPageNumberLimit + 1) && number > minPageNumberLimit) {
            return (
                <li key={number} id={number.toString()} onClick={handleClick} className={` ${currentPage === number && "page-active-now"}`}>
                    {number}
                </li>
            )
        } else return null
    })

    // const BILLING_CYCLES_PER_PAGE = 5;

    // const billingCyclesData = useMemo(() => {
    //     let computedAudienceList = billingCycles;
    //     setTotalItems(computedAudienceList.length);

    //     //Current Page slice
    //     return computedAudienceList.slice((currentPage - 1) * BILLING_CYCLES_PER_PAGE, (currentPage - 1) * BILLING_CYCLES_PER_PAGE + BILLING_CYCLES_PER_PAGE);
    // }, [billingCycles, currentPage]);

    const [billingCycleCustom, setBillingCycleCustom] = useState<AnalyticsParams>({
		dataFrom: "",
		dataTo: ""
	});

    const handleBillingCycleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setBillingCycleCustom({
			...billingCycleCustom,
			[name]: value
		})
	}

    const handleCustomDateSelection = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
        setLoading(true);
		getBillingCycles(new Date(billingCycleCustom.dataFrom), new Date(billingCycleCustom.dataTo));
	}

    const resetBackToDefault = () => {
        setLoading(true);
        getBillingCycles(new Date("2018-01-01"), new Date());
		setBillingCycleCustom({
			dataFrom: "",
			dataTo: ""
		})
	}


    useEffect(() => {
        getBillingCycles(new Date(query.get("dataFrom") ?? "2018-01-01"), new Date(query.get("dataTo") ?? new Date()));
        // eslint-disable-next-line
    }, []);

    if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	} 

    const goToUserPage = (userId: string) => {  
        // console.log(userId)      
        history.push(`/user/${userId}?fromAccount=${true}&dataFrom=${billingCycleCustom.dataFrom}&dataTo=${billingCycleCustom.dataTo}`);
    }
    
    return (
        <div>
            <form onSubmit={handleCustomDateSelection} className="custom-date-range-form">
                <h4>Custom Date Range Selection</h4>
                <div className="input-div">
                    <label htmlFor="dataFrom">Date From</label>
                    <input type="date" className="form-control" name="dataFrom" id="dataFrom" required value={billingCycleCustom.dataFrom} onChange={handleBillingCycleChange} />
                </div>
                <div className="input-div">
                    <label htmlFor="dataTo">Date To</label>
                    <input type="date" className="form-control" name="dataTo" id="dataTo" required value={billingCycleCustom.dataTo} onChange={handleBillingCycleChange} />
                </div>
                <div className="d-flex justify-content-between custom-btn-div">
                    <button className="btn btn-dark btn-md font-weight-medium" type="submit">Use Custom Date</button>
                    <button className="btn btn-dark btn-md font-weight-medium" type="button" onClick={resetBackToDefault}>Reset Back To Default</button>
                </div>
			</form>
            <div className="card-body p-3 d-flex justify-content-between" id="searchBody">
                <div className="form-group m-0 template-search-div" style={{ width: "45%", padding: "1.25rem" }}>
                    <label>Filter By Plan</label>
                    <select className="form-control sort-select" value={filterPlanSelect} onChange={handleFilterByPlan}>
                        <option value="default">Default</option>
                        <option value="free">Free</option>
                        <option value="in-pro">Inbranded Pro</option>
                    </select>
                </div>
                <div className="form-group m-0 template-search-div" style={{ width: "45%", padding: "1.25rem" }}>
                    <label>Filter By Billing Cycles</label>
                    <select className="form-control sort-select" value={filterBillingCyclesSelect} onChange={handleFilterByBillingCycle}>
                        <option value="default">Default</option>
                        <option value="overages">Billing Cycles With Overages</option>
                        <option value="paid">Paid Billing Cycles</option>
                        <option value="unpaid">UnPaid Billing Cycles</option>
                    </select>
                </div>
            </div>
            <div className="card-body no-padding">
                <div className="table-wrap table-responsive card">
                    <table className="table m-0 table-hover">
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Created</th>
                                <th>Billing Period </th>
                                <th>Estimated Cost</th>
                                <th>Counts and Quota</th>
                                <th>Active</th>
                                <th>Paid</th>
                                <th>Renewing</th>
                                <th>Amount Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentItems.length > 0 ? (
                                    currentItems.map((billingCycle : BillingCycleProps) => (
                                        <tr key={billingCycle.id} style={{ cursor: "pointer" }} onClick={() => goToUserPage(billingCycle.userId)}>
                                            <td className="custom-text-capitalize">
                                                {billingCycle.plan}
                                            </td>
                                            <td>{parseISOString(billingCycle.createdAt)}</td>
                                            <td>{justDate(billingCycle.startsAt)} - {justDate(billingCycle.endsAt)}</td>
                                            <td>{billingCycle.estimatedCost}</td>
                                            <td>
                                            <p>Automation Count: {billingCycle.automationJobCount}</p>
                                            <p>Automation Quota: {billingCycle.automationJobQuota}</p>
                                            <p>Generated Image Count: {billingCycle.generatedImageCount}</p>
                                            <p>Generated Image Quota: {billingCycle.generatedImageQuota}</p>
                                            <p>Email Sent Count: {billingCycle.emailSendCount}</p>
                                            <p>Email Sent Quota: {billingCycle.emailSendQuota}</p>
                                            </td>
                                            <td>
                                                {
                                                    billingCycle.isActive ? <span className="badge badge-outline-success">Active</span> : <span className="badge inactive-badge">Inactive</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    billingCycle.isPaid ? <span className="badge badge-outline-success">Paid</span> : <span className="badge badge-outline-danger">Not paid</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    billingCycle.isRenewing ? <span className="badge badge-outline-success">Yes</span> : <span className="badge badge-outline-warning">No</span>
                                                }
                                            </td>
                                            <td>{billingCycle.currency} &nbsp; {billingCycle.amountPaid}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8} className={` text-center`}>
                                            No Data Found
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    {/* <PaginationComponent total={totalItems} itemsPerPage={BILLING_CYCLES_PER_PAGE} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} /> */}
                </div>
                <ul className="page-numbers">
                    <li><button onClick={handlePagePrev} disabled={currentPage === pages[0] ? true : false }>Prev</button></li>
                        { pageDecrementBtn }
                        { renderPageNumbers }
                        { pageIncrementBtn }
                    <li><button onClick={handlePageNext} disabled={currentPage === pages[pages.length - 1] ? true : false }>Next</button></li>
                </ul>
            </div>
        </div>
    )
}

export default BillingCycles
