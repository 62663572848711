import React, { useEffect, useReducer } from 'react'
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import PageTab from '../../shared/PageTab'
import { workflowsApis } from '../../apis/WorkflowApi';
import { useDebounce } from 'use-debounce';
import StatsCard from '../../shared/StatsCard';
import AnalyticsTopSection from '../../shared/AnalyticsTopSection';
import CustomPagination from '../../shared/CustomPagination';

// interface WorkflowStatsType {
//   published: string | number
//   paused: string | number
//   draft: string | number
// }

const initialData = {
  main: {
      stats: {instancesStats: {}, taskStats: {}, workflowStats: []}, 
      dateFrom: "", dateTo: "", userId: "", 
      query: "", perPage: 20, page: 1,
      sortBy: "name", orderBy: "ASC",
  },
  loading: true,
  error: ""
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.field]: action.fieldValue };
    case "updateBooleans":
      return { ...state, loading: action.fieldValue };
    case "reset":
      return initialData;
    default:
      return state;
  }
};

function Stats() {
    const tabs = [
        {link: "/workflows", text: "Workflows"},
        {link: "/workflows/stats", text: "Stats"}
    ]
  
      const [data, setData] = useReducer(reducer, initialData);
      const workflows = data.main.stats.workflowStats
      const instances = data.main.stats.instancesStats
      const tasks = data.main.stats.taskStats

      const page = data.main.page 
      const perPage = data.main.perPage 
      const userId = data.main.userId 
      const query = data.main.query 
      const sortBy = data.main.sortBy 
      const orderBy = data.main.orderBy 
  
      const [debouncedQuery] = useDebounce(query, 1200);
      const [debouncedUserIdQuery] = useDebounce(userId, 1200);
  
      const {stats, ...payLoadParams} = data.main
      
  
      const getWorkflowsStats = async (payLoad: typeof payLoadParams) => {
          setData({
              type: "update",
              field: "loading",
              fieldValue: true,
            });
          const result = await workflowsApis.getWorkflowsStats(payLoad);
          const {searchParams, search, status, ...serverStats} = result
          if(status === "ok"){
          setData({
            type: "update",
            field: "main",
            fieldValue: {stats: serverStats, ...searchParams},
          });
      } else {
        setData({
          type: "update",
          field: "error",
          fieldValue: result.message,
        });
      }
      setData({ type: "updateBooleans", fieldValue: false });
      }
  
       
      useEffect(() => {
        getWorkflowsStats(payLoadParams)
        // eslint-disable-next-line
      }, [
           debouncedUserIdQuery, 
           debouncedQuery,
          perPage, page, sortBy, 
          orderBy
      ])
  
      if (data.loading) {
      return <Roller />
    } else if (data.error === "Invalid Permission") {
          return <NotAuthorized />
      } else if (data.error !== "") {
      return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
        {data.error}
      </div>
    } 
    
    const workflowsStatsDetails = {
        title: "Workflows", 
        items: [{
          name: "Published", 
          value: workflows[0].published ?? 0
        },
        {
          name: "Paused", 
          value: workflows[0].paused ?? 0
        },
        {
          name: "Draft", 
          value: workflows[0].draft ?? 0
        },
        ]
      }

      const instacesStatsDetails = {
        title: "Instances", 
        items: [{
          name: "Active", 
          value: instances.active ?? 0
        },
        {
          name: "Completed", 
          value: instances.completed ?? 0
        },
        {
          name: "Cancelled", 
          value: instances.cancelled ?? 0
        },
        {
          name: "Total", 
          value: instances.total ?? 0
        },
        ]
      }
                    
      const tasksStatsDetails = {
        title: "Tasks", 
        items: [{
          name: "Completed", 
          value: tasks.completed ?? 0
        },
        {
          name: "Scheduled", 
          value: tasks.scheduled ?? 0
        },
        {
          name: "Cancelled", 
          value: tasks.cancelled ?? 0
        },
        {
          name: "Skipped",
          value: tasks.skipped ?? 0
        },
        {
          name: "Failed",
          value: tasks.failed ?? 0
        },
        {
          name: "Total", 
          value: instances.total ?? 0
        },
        ]
      }

      const apiFunction = () => {
        getWorkflowsStats(payLoadParams)
      }

    return (
      <>
      <PageTab tabs={tabs} />
      
      <div className="card">
              <AnalyticsTopSection 
                data={data}
                setData={setData}
                apiFunction={apiFunction}
              />
                  <div className="card-body d-flex justify-content-between flex-wrap my-2">
                    {/* stats card */}

                    <StatsCard 
                    cardDetails={workflowsStatsDetails}
                    />

                    {/* {<div className='col-12 col-md-4 stats-card-wrapper'>
                 <div className='stats-card' style={{border: "none"}}>
                        <h5 className='mb-4 text-center'>{`Workflows`}</h5>
                        <div className='d-flex align-items-center justify-content-between'>
                        <h6 className='col-3 text-success'>published</h6> <h6 className='col-3 text-success'>paused</h6> <h6 className='col-3 text-success'>draft</h6>
                        </div>
                        {stats.workflowStats.length > 0 && stats.workflowStats.map((item: WorkflowStatsType, i: number) => (
                            <div key={i} className='d-flex align-items-center justify-content-between'>
                                <h6 className='col-3'>{item.published}</h6> 
                                <h6 className='col-3'>{item.paused}</h6> 
                                <h6 className='col-3'>{item.draft}</h6> 
                                </div>
                        ))}
                 </div>
                 </div>} */}
                    
                 {/*  */}
                 <StatsCard 
                    cardDetails={instacesStatsDetails}
                    />
                 {/*  */}
                 <StatsCard 
                 cardDetails={tasksStatsDetails}
                 />

              </div>
        <CustomPagination 
        data={data}
        setData={setData}
        apiFunction={()=>{}}
        />
          </div>
      </>
    )
    
}

export default Stats