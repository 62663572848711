import React from 'react'

interface Props {
    cardDetails : {
        title: string;
        items: {name: string; value: string}[]
    }
}

function StatsCard({cardDetails} : Props) {
  return (
    <div className='col-12 col-md-4 stats-card-wrapper'>
                 <div className='stats-card'>
                        <h3 className='mb-4 text-center'>{cardDetails.title}</h3>
                        {cardDetails.items.map(item => (
                            <div key={item.name} className='d-flex align-items-center justify-content-between'><h6 className='text-success'>{item.name}</h6> <h3> {item.value}</h3></div>
                        ))}
                 </div>
                 </div>
  )
}

export default StatsCard