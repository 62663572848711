import { toQueryString } from "./refineData";

let token = localStorage.getItem("inbrAdminToken");
if (token) { token = JSON.parse(token); }

interface SubsParams {
    dateFrom: string, dateTo: string, userId: string, 
    planId: string
}

const getSubscriptions = async (payLoad: SubsParams) => {

    let queryString: string = toQueryString(payLoad);

    // console.log("starting");
    // console.log(dataFrom, dataTo)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/subscriptions?${queryString}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

const getSubsStats = async (payLoad: SubsParams) => {
	let queryString: string = toQueryString(payLoad);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/subscriptions/stats?${queryString}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

const getSubscription = async (subId: string) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",  `Bearer ${token}`);

    let requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
		const response = await fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/subscriptions/${subId}`, requestOptions);

		if (response.status === 404) {
			return response;
		} else if (response.status !== 200) {
			const result = await response.json();
			return result;
		} else {
			const result = await response.json();
			return result;
		}
	} catch (error) {
		// console.log(error, "the error");
		return {
			status: "myCustomErr",
			error: "Your Connection Timed Out",
		};
	}
}

export const subscriptionsApis = { getSubscriptions, getSubsStats, getSubscription }