import React from 'react';
import { AnalyticsParamsCampaigns, CampaignResults } from '../admin-pages/campaigns/Campaigns';
import { useHistory } from 'react-router-dom';
export interface CampaignTableRowProps {
    category: null
    id: string,
    isPublished: false
    name: string,
    stats: {
        commentCount: number, 
        entryCount: number, 
        pageViews: number
    },
    type: string,
    campaignResult: CampaignResults,
    dateData: AnalyticsParamsCampaigns,
    fromUser?: string
}

const CampaignTableRow : React.FC<CampaignTableRowProps> = ({ name, id, type, isPublished, stats: { pageViews, entryCount, commentCount }, campaignResult, dateData, fromUser }) => {
    const history = useHistory();
    
    const goToCampaignPage = () => {
        console.log(campaignResult);
        console.log(dateData);
        history.push(`/campaign/${id}?fromUser=${fromUser}&query=${campaignResult.query}&category=${campaignResult.category}&dateFrom=${dateData.dateFrom}&dateTo=${dateData.dateTo}&orderBy=${campaignResult.orderBy}&sortBy=${campaignResult.sortBy}&page=${campaignResult.page}&perPage=${campaignResult.perPage}`);
    }

    return (
        <tr>
            <td>
                <span className="custom-link you-can-click" onClick={goToCampaignPage}>{name}</span>
            </td>
            <td className="custom-text-capitalize">{type}</td>
            <td>
                {
                    isPublished ? <span className="badge badge-outline-success">Published</span> : <span className="badge badge-outline-warning">Not published</span>
                }
            </td>
            <td>{commentCount}</td>
            <td>{pageViews}</td>
            <td>{entryCount}</td>
            <td>
                <span className="btn btn-sm btn-default custom-link you-can-click" onClick={goToCampaignPage}>
                    View &rarr;{" "}
                </span>
            </td>
        </tr>
    )
}

export default CampaignTableRow
