import React, { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import PageTab from '../../shared/PageTab'
import { workflowsApis } from '../../apis/WorkflowApi';
import { parseISOString } from '../../shared/parseDate';
import { useDebounce } from 'use-debounce';
import AnalyticsTopSection from '../../shared/AnalyticsTopSection';
import CustomPagination from '../../shared/CustomPagination';

const initialData = {
    main: {
        workflows: [], dateFrom: "", dateTo: "", 
        userId: "", query: "", perPage: 20, page: 1,
        sortBy: "name", status: "", orderBy: "ASC", count: 0
    },
    loading: true,
    error: ""
  };
  
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "update":
        return { ...state, [action.field]: action.fieldValue };
      case "updateBooleans":
        return { ...state, loading: action.fieldValue };
      case "reset":
        return initialData;
      default:
        return state;
    }
  };

function Workflows() {
    const tabs = [
        {link: "/workflows", text: "Workflows"},
        {link: "/workflows/stats", text: "Stats"}
]

	const history = useHistory();

    const [data, setData] = useReducer(reducer, initialData);
    const userId = data.main.userId 
    const query = data.main.query 
    const perPage = data.main.perPage 
    const page = data.main.page 
    const sortBy = data.main.sortBy 
    const status = data.main.status 
    const orderBy = data.main.orderBy 

    const [debouncedQuery] = useDebounce(query, 1200);
    const [debouncedUserIdQuery] = useDebounce(userId, 1200);

    const {workflows, count, ...payLoadParams} = data.main

    const getWorkflows = async (payLoad: typeof payLoadParams) => {
        setData({
            type: "update",
            field: "loading",
            fieldValue: true,
          });
        const result = await workflowsApis.getWorkflows(payLoad);
        if(result.status === "ok"){
        setData({
          type: "update",
          field: "main",
          fieldValue: {workflows: result.workflows, ...result.searchParams, total: result.count},
        });
    } else {
      setData({
        type: "update",
        field: "error",
        fieldValue: result.message,
      });
    }
    setData({ type: "updateBooleans", fieldValue: false });
    }

    useEffect(() => {
      getWorkflows(payLoadParams)
      // eslint-disable-next-line
    }, [
         debouncedUserIdQuery, debouncedQuery,
        perPage, page, sortBy, status, orderBy
    ])

    const gotoWorkflow = (id: string) => {
        history.push(`/workflows/${id}`)
    }

    if (data.loading) {
		return <Roller />
	} else if (data.error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (data.error !== "") {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{data.error}
		</div>
	} 

    const apiFunction = () => {
        getWorkflows(payLoadParams)
    }

  return (
    <>
    <PageTab tabs={tabs} />
    <div className="card">
            <AnalyticsTopSection 
            data={data}
            setData={setData}
            apiFunction={apiFunction}
            />
                <div className="card-body no-padding">
                <div className="table-wrap table-responsive">
                <table className="table m-0 table-hover">
                <thead>
                <tr>
                <th>Name</th>
                <th>Active Instance</th>
                <th>Status</th>   
                <th>Published At</th> 
                <th>Created At</th>
                <th>Updated At</th>                      
                </tr>
                </thead>
                <tbody>
                {
                data.main.workflows.length === 0 ? (
                <tr>
                <td colSpan={6} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                No Data Found
                </td>
                </tr>
                ) : (
                data.main.workflows.map((item: any) => (
                <tr key={item.id} className={`you-can-click`} 
                onClick={() => gotoWorkflow(item.id)}
                >
                <td className="custom-text-capitalize">{item.name}</td>
                <td>{item.activeInstance}</td>
                <td>
                {
                item.workflowStatus === "published" ? <span className="badge badge-outline-success">{item.workflowStatus}</span> : <span className="badge badge-outline-warning">{item.workflowStatus}</span>
                }
                </td>
                <td>{item.publishedAt ? parseISOString(item.publishedAt) : "-"}</td>
                <td>{item.createdAt ? parseISOString(item.createdAt) : "-"}</td>
                <td>{item.updatedAt ? parseISOString(item.updatedAt) : "-"}</td>
                </tr>
                ))
                )
                }

                </tbody>
                </table>
                </div>
            </div>
			<CustomPagination
        data={data}
        setData={setData}
        apiFunction={()=>{}}
      />
        </div>
    </>
  )
}

export default Workflows