import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import Spinner from "./Spinner";
import logoMini from "../../assets/images/logo/logo.png";
import useAuth from "../auth/useAuth";
import Swal from "sweetalert2";
interface adminObject {
	createdAt: string
	email: string
	firstName: string
	id: string
	lastName: string
	meta: any
	role: string
	status: number
	updatedAt: string
}

const Navbar = () => {
    let token = localStorage.getItem("inbrAdminToken");

    if (token) {
        token = JSON.parse(token);
    }
	const history = useHistory();
	const auth = useAuth();

	
	useEffect(() => {
		const intervalId = setInterval(() => {
			getAdminProfile();
		}, 300000); // Fetch the admin info every 5minutes      

		return () => clearInterval(intervalId);
		// eslint-disable-next-line
	}, []);
	
	const [adminProfile, setAdminProfile] = useState<adminObject>({
		createdAt: "",
		email: "",
		firstName: "",
		id: "",
		lastName: "",
		meta: null,
		role: "",
		status: 0,
		updatedAt: ""
	});
	const [error, setError] = useState("");

	const toggleOffcanvas = () => {
		document?.querySelector(".sidebar-offcanvas")?.classList.toggle("active");
	}

	
	useEffect(() => {
		if (error) {
			Swal.fire({
				icon: "error",
				title: error,
			}).then(() => logoutAdmin())
		}		
		// eslint-disable-next-line
	}, [error])

	const logoutAdmin = () => {
		console.log("clicked")
		localStorage.removeItem("inbrAdminToken");
		localStorage.removeItem("adminProfile");
		history.push("/");
	}

	useEffect(() => {
		const adminProfileLS = localStorage.getItem("adminProfile");
		// console.log(adminProfile, "admin object");
		if (auth?.adminProfile) {
			setAdminProfile(auth?.adminProfile);
			localStorage.setItem("adminProfile", JSON.stringify(auth?.adminProfile))
		} else if (adminProfileLS) {
			auth?.setAdminProfile(JSON.parse(adminProfileLS));
		} else {
			logoutAdmin();
		}
		// eslint-disable-next-line
	}, [auth?.adminProfile]);

	// useEffect(() => console.log(adminProfile, "admin"), [adminProfile]);

	if (!adminProfile) {
		return <Spinner />
	}

	function getAdminProfile () : void {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/auth/user-info`, requestOptions)
		.then(response => response.json())
		.then(result => {
			if (result.error) {
				setError(result.error)
			} else if (result.status === "ok") {
				auth?.setAdminProfile(result.admin);
			}
			
			// console.log(result);
		})
		.catch(error => {
			setError("Please check your connection and try again");
            console.log(error);
		});
	}
	// const toggleRightSidebar = () => {
	// 	document.querySelector(".right-sidebar")?.classList.toggle("open");
	// }
	
	return (
		<nav className="navbar p-0 fixed-top d-flex flex-row">
			<div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
				<Link className="navbar-brand brand-logo-mini" to="/">
					<img src={logoMini} alt="logo" />
				</Link>
			</div>
			<div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
				<button className="navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle("sidebar-icon-only")}>
					<span className="mdi mdi-menu"></span>
				</button>
				{/* <ul className="navbar-nav w-100">
					<li className="nav-item w-100">
						<form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
							<input type="text" className="form-control" placeholder="Search products" />
						</form>
					</li>
				</ul> */}
				<ul className="navbar-nav navbar-nav-right">
					{/* <Dropdown alignRight as="li" className="nav-item d-none d-lg-block">
						<Dropdown.Toggle className="nav-link btn btn-success create-new-button no-caret">
							+ <Trans>Create New Project</Trans>
						</Dropdown.Toggle>

						<Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu">
							<h6 className="p-3 mb-0">
								<Trans>Projects</Trans>
							</h6>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-file-outline text-primary"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject ellipsis mb-1">
										<Trans>Software Development</Trans>
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-web text-info"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject ellipsis mb-1">
										<Trans>UI Development</Trans>
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-layers text-danger"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject ellipsis mb-1">
										<Trans>Software Testing</Trans>
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<p className="p-3 mb-0 text-center">
								<Trans>See all projects</Trans>
							</p>
						</Dropdown.Menu>
					</Dropdown>
					<li className="nav-item d-none d-lg-block">
						<a className="nav-link" href="!#" onClick={(event) => event.preventDefault()}>
							<i className="mdi mdi-view-grid"></i>
						</a>
					</li>
					<Dropdown alignRight as="li" className="nav-item border-left">
						<Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
							<i className="mdi mdi-email"></i>
							<span className="count bg-success"></span>
						</Dropdown.Toggle>
						<Dropdown.Menu className="navbar-dropdown preview-list">
							<h6 className="p-3 mb-0">
								<Trans>Messages</Trans>
							</h6>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<img src="" alt="profile" className="rounded-circle profile-pic" />
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject ellipsis mb-1">
										<Trans>Mark send you a message</Trans>
									</p>
									<p className="text-muted mb-0">
										{" "}
										1 <Trans>Minutes ago</Trans>{" "}
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<img src="" alt="profile" className="rounded-circle profile-pic" />
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject ellipsis mb-1">
										<Trans>Cregh send you a message</Trans>
									</p>
									<p className="text-muted mb-0">
										{" "}
										15 <Trans>Minutes ago</Trans>{" "}
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<img src="" alt="profile" className="rounded-circle profile-pic" />
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject ellipsis mb-1">
										<Trans>Profile picture updated</Trans>
									</p>
									<p className="text-muted mb-0">
										{" "}
										18 <Trans>Minutes ago</Trans>{" "}
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<p className="p-3 mb-0 text-center">
								4 <Trans>new messages</Trans>
							</p>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown alignRight as="li" className="nav-item border-left">
						<Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
							<i className="mdi mdi-bell"></i>
							<span className="count bg-danger"></span>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
							<h6 className="p-3 mb-0">
								<Trans>Notifications</Trans>
							</h6>
							<Dropdown.Divider />
							<Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-calendar text-success"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject mb-1">
										<Trans>Event today</Trans>
									</p>
									<p className="text-muted ellipsis mb-0">
										<Trans>Just a reminder that you have an event today</Trans>
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-settings text-danger"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<h6 className="preview-subject mb-1">
										<Trans>Settings</Trans>
									</h6>
									<p className="text-muted ellipsis mb-0">
										<Trans>Update dashboard</Trans>
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-link-variant text-warning"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<h6 className="preview-subject mb-1">
										<Trans>Launch Admin</Trans>
									</h6>
									<p className="text-muted ellipsis mb-0">
										<Trans>New admin wow</Trans>!
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<p className="p-3 mb-0 text-center">
								<Trans>See all notifications</Trans>
							</p>
						</Dropdown.Menu>
					</Dropdown> */}
					<Dropdown alignRight as="li" className="nav-item">
						<Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
							<div className="navbar-profile">
								{/* <img className="img-xs rounded-circle" src="" alt="profile" /> */}
								<p className="mb-0 d-none d-sm-block navbar-profile-name">
									<Trans>{adminProfile.firstName} {adminProfile.lastName}</Trans>
								</p>
								<i className="mdi mdi-menu-down d-none d-sm-block"></i>
							</div>
						</Dropdown.Toggle>

						<Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
							<h6 className="p-3 mb-0">
								<Trans>Profile</Trans>
							</h6>
							<Dropdown.Divider />
							<Dropdown.Item href="/profile" onClick={e => {
								e.preventDefault();
								history.push(`/profile`);
							}} className="preview-item">
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-account-circle text-success"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject mb-1">
										<Trans>Your Profile</Trans>
									</p>
								</div>
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item href="!#" 
								onClick={(evt) => {
									evt.preventDefault();
									logoutAdmin();
								}} 
								className="preview-item"
							>
								<div className="preview-thumbnail">
									<div className="preview-icon bg-dark rounded-circle">
										<i className="mdi mdi-logout text-danger"></i>
									</div>
								</div>
								<div className="preview-item-content">
									<p className="preview-subject mb-1">
										<Trans>Log Out</Trans>
									</p>
								</div>
							</Dropdown.Item>
							{/* <Dropdown.Divider />
							<p className="p-3 mb-0 text-center">
								<Trans>Advanced settings</Trans>
							</p> */}
						</Dropdown.Menu>
					</Dropdown>
				</ul>
				<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
					<span className="mdi mdi-format-line-spacing"></span>
				</button>
			</div>
		</nav>
	);
	
}

export default Navbar;
