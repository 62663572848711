import React, {useEffect, useReducer} from 'react'
import { useParams } from "react-router-dom";
import { workflowsApis } from "../../apis/WorkflowApi"
import { useHistory } from 'react-router-dom';
import useAuth from '../../auth/useAuth';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import { parseISOString } from '../../shared/parseDate';

interface UseParamsProps {
  id: string
}

const initialData = {
  main: {
      workflow: {}
  },
  loading: true,
  error: ""
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.field]: action.fieldValue };
    case "updateBooleans":
      return { ...state, loading: action.fieldValue };
    case "reset":
      return initialData;
    default:
      return state;
  }
};

function Workflow() {
  let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();
    if (token) {
        token = JSON.parse(token);
    }
    const auth = useAuth();
    useEffect(() => {
        if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

  const { id } = useParams<UseParamsProps>()
  const [data, setData] = useReducer(reducer, initialData);

  const getWorkflow = async (id: string) => {
    const result = await workflowsApis.getWorkflow(id);
    if(result.status === "ok"){
      setData({
        type: "update",
        field: "main",
        fieldValue: {workflow: result.workflow},
      });
  } else {
    setData({
      type: "update",
      field: "error",
      fieldValue: result.message,
    });
  }
  setData({ type: "updateBooleans", fieldValue: false });
  }

  useEffect(() => {
    getWorkflow(id)
  }, [id])
  
  if (data.loading) {
		return <Roller />
	} else if (data.error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (data.error !== "") {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{data.error}
		</div>
	} 
  const workflow = data.main.workflow

  const StatusComponent = (status: string) => {
    return status === "published" ? <span className="badge badge-outline-success">{status}</span> : <span className="badge badge-outline-warning">{status}</span>
  }

  const workflowDetails = [{
      name: "User Id", 
      value: workflow.userId ?? "---"
    },
    {
      name: "Name", 
      value: workflow.name ?? "---"
    },
    {
      name: "Status", 
      value: StatusComponent(workflow.workflowStatus) ?? "---"
    },
    {
      name: "Active Instance", 
      value: workflow.activeInstance ?? 0
    },
    {
      name: "Created at", 
      value: workflow.createdAt ? parseISOString(workflow.createdAt) : "---"
    },
    {
      name: "Updated at", 
      value: workflow.updatedAt ? parseISOString(workflow.updatedAt) : "---"
    },
    {
      name: "Published at", 
      value: workflow.publishedAt ? parseISOString(workflow.publishedAt) : "---"
    },
    {
      name: "Last executed at", 
      value: workflow.lastExecutedAt ? parseISOString(workflow.lastExecutedAt) : "---"
    },
    {
      name: "Last result at", 
      value: workflow.lastResultAt ? parseISOString(workflow.lastResultAt) : "---"
    },
    {
      name: "Description", 
      value: workflow.description ?? "---"
    },
    ]

    const handleBack = () => {
      history.push("/workflows")
    }

    const handleGotoInstances = () => {
      history.push(`/workflows/${id}/instances`)
    }

  return (
    <>
      <button className='mx-2 my-1 back-button' onClick={handleBack}>« Back</button>
      <div className='col-12 mt-1 mb-4'>
                 <div className='stats-card'>
                 <div className='d-flex justify-content-between align-items-center mb-3'>
                  <h3>Workflow</h3>
                  <button 
                  className='main-button'
                  onClick={handleGotoInstances}
                  >
                    Instances
                    </button>
                 </div>
                 <div className='divider mb-4'></div>
                        {workflowDetails.map(item => (
                            <div key={item.name} className='d-flex align-items-baseline justify-content-between'><h5 className='text-success col-4'>{item.name}</h5> <p className='col-8'> {item.value}</p></div>
                        ))}
                 </div>
                 </div>
    </>
  )
}

export default Workflow