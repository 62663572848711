export const parseISOString = (ISOString: string) : string => {
    let d = ISOString.split(/\D+/);
    let theUtcTime = new Date(Date.UTC(Number(d[0]), /* -- */Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
    return (
        theUtcTime
            .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "numeric",
                year: "numeric",
            })
            .replace(/ /g, "/") +
        ", " +
        theUtcTime.toLocaleTimeString()
    );
};