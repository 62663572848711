import React, { useEffect, useState } from 'react'
import { useHistory,useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import useAuth from '../../auth/useAuth';
import CampaignTableRow, { CampaignTableRowProps } from '../../components/CampaignTableRow';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';

export interface CampaignResults {
    category : string,
    orderBy : string,
    page : string,
    perPage : string,
    query : string,
    sortBy : string,
    userId : string,
}
export interface AnalyticsParamsCampaigns {
    dateFrom: string,
    dateTo: string
}

const Campaigns : React.FC = () => {
    let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();
    
    const useQuery = () => new URLSearchParams(useLocation().search);
	let query = useQuery();

    if (token) {
        token = JSON.parse(token);
    }

    const auth = useAuth();

    useEffect(() => {
        if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [campaigns, setCampaigns] = useState<[]>([]);
	const [campaignResult, setCampaignResult] = useState<CampaignResults>({
        category : "",
        orderBy : "",
        page : "",
        perPage : "",
        query : "",
        sortBy : "",
        userId : "",
    });  

    const [campaignCustomDate, setCampaignCustomDate] = useState<AnalyticsParamsCampaigns>({
		dateFrom: "",
		dateTo: ""
	});

    // useEffect(() => console.log(campaignCustomDate, "campaignCustomDate"), [campaignCustomDate]);

    const handleCustomDateCampaignChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setCampaignCustomDate({
			...campaignCustomDate,
			[name]: value
		})
	}

    function getCampaigns (query: string = campaignResult.query, userId: string = "", perPage: number = 10, page: number = 1, category: string = "", sortBy: string = campaignResult.sortBy, orderBy: string = campaignResult.orderBy, dateFrom: object = new Date("2018-01-01"), dateTo: object = new Date()) : void {
		setLoading(true);
        let queryString: string = `${query}&userId=${userId}&perPage=${perPage}&page=${page}&category=${category}&sortBy=${sortBy}&orderBy=${orderBy}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

		// console.log("starting");
		// console.log(dateFrom, dateTo)
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization",  `Bearer ${token}`);

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
		};

		fetch(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/campaigns?query=${queryString}`, requestOptions)
		.then(response => response.json())
		.then(result => {
            setLoading(false);
            if (result.status === "ok") {
                setCampaigns(result.campaigns);
                setCampaignResult(result.results);
                setCampaignCustomDate({
                    ...campaignCustomDate,
                    dateFrom: result.results.dateFrom,
                    dateTo: result.results.dateTo,
                })
            } else if (result.error) {
                setError(result.error);
                if (result.error !== "Invalid Permission") {
                    Swal.fire({
                        icon: "error",
                        title: result.error
                    });
                }
            }
            console.log(result);
		})
		.catch(error => {
            console.log(error);
			setError("Error please check your internet connection");
			setLoading(false);
		});
	}

    const [sortByState, setSortByState] = useState("");
	const [orderByState, setOrderByState] = useState("");

	const sortBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(e.target.value);

		if (e.target.value === "") {
			setSortByState("");
			getCampaigns("", "", 10, 1, "", "", orderByState);
		} else {
			setSortByState(e.target.value);
			getCampaigns("", "", 10, 1, "", e.target.value, orderByState);
		}
	};

	const orderBySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(e.target.value);

		if (e.target.value === "") {
			setOrderByState("");
			getCampaigns("", "", 10, 1, "", sortByState, "");
		} else {
			setOrderByState(e.target.value);
			getCampaigns("", "", 10, 1, "", sortByState, e.target.value);
		}
	};

    let searchSeconds = 1000; // milliseconds for the search timeout
	const [nameSearchValue, setNameSearchValue] = useState("");

	useEffect(() => {
		let nameSearchId: NodeJS.Timeout;

		if (nameSearchValue) {
			nameSearchId = setTimeout(() => {
				setLoading(true);
				getCampaigns(nameSearchValue);
			}, searchSeconds);
		}

		return () => clearTimeout(nameSearchId);

		// eslint-disable-next-line
	}, [nameSearchValue, searchSeconds]);

    const [currentPage, setCurrentPage] = useState(1);

	const prev = () => {
		if (Number(campaignResult.page) === 1) {
			return;
		} else {
			getCampaigns(campaignResult.query, "", 10, Number(currentPage) - 1, "", campaignResult.sortBy, campaignResult.orderBy, new Date(campaignCustomDate.dateFrom), new Date(campaignCustomDate.dateTo));
			setCurrentPage(currentPage - 1);
			// console.log("previous");
		}
	};

	const next = () => {
		if (campaigns.length !== 0) {
			setCurrentPage(Number(campaignResult.page) + 1);
			getCampaigns(campaignResult.query, "", 10, Number(campaignResult.page) + 1, "", campaignResult.sortBy, campaignResult.orderBy, new Date(campaignCustomDate.dateFrom), new Date(campaignCustomDate.dateTo));
		} else {
			return;
		}
	};

    const handleCustomDateSelection = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
        setLoading(true);
		getCampaigns("", "", 10, 1, "", campaignResult.sortBy, campaignResult.orderBy, new Date(campaignCustomDate.dateFrom), new Date(campaignCustomDate.dateTo));
	}

    const resetBackToDefault = () => {
        setLoading(true);
        getCampaigns("", "", 10, 1, "", campaignResult.sortBy, campaignResult.orderBy, new Date("2018-01-01"), new Date());
		setCampaignCustomDate({
			dateFrom: "",
			dateTo: ""
		})
	}


    useEffect(() => {        
        getCampaigns(query.get('query') ?? "", "", Number(query.get('perPage') ?? 10), Number(query.get('page') ?? 1), query.get('category') ?? "", query.get('sortBy') ?? "", query.get('orderBy') ?? "", new Date(query.get('dateFrom') ?? "2018-01-01"), new Date(query.get('dateTo') ?? new Date()));
    
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
		setNameSearchValue(campaignResult?.query);
        setOrderByState(campaignResult?.orderBy);
        setSortByState(campaignResult?.sortBy);
	}, [campaignResult]);

    function date(value: string | number) {
        let date = new Date(value);
        //zero-pad a single zero if needed
        let zp = function (value: string | number){
            return (value <= 9 ? '0' + value : '' + value);
        }
    
        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        return ''+ y + '-' + zp(m) + '-' + zp(d);
    }

    if (loading) {
		return <Roller />
	} else if (error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (error) {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{error}
		</div>
	} 

    return (
        <div className="card">
            <div className="card-header">                
                <form onSubmit={handleCustomDateSelection} className="custom-date-range-form">
                    <h4>Custom Date Range Selection</h4>
                    <div className="input-div">
                        <label htmlFor="dateFrom">Date From</label>
                        <input type="date" className="form-control" name="dateFrom" id="dateFrom" required value={query.get('dateFrom') ? date(campaignCustomDate.dateFrom) : campaignCustomDate.dateFrom} onChange={handleCustomDateCampaignChange} />
                    </div>
                    <div className="input-div">
                        <label htmlFor="dateTo">Date To</label>
                        <input type="date" className="form-control" name="dateTo" id="dateTo" required value={query.get('dateTo') ? date(campaignCustomDate.dateTo) : campaignCustomDate.dateTo} onChange={handleCustomDateCampaignChange} />
                    </div>
                    <div className="d-flex justify-content-between custom-btn-div">
                        <button className="btn btn-dark btn-md font-weight-medium" type="submit">Use Custom Date</button>
                        <button className="btn btn-dark btn-md font-weight-medium" type="button" onClick={resetBackToDefault}>Reset Back To Default</button>
                    </div>
                </form>
                <div className="card mb-1">
                    <div className="card-body p-3 d-flex justify-content-between" id="searchBody">
                        <div className="form-group m-0 template-search-div">
                            <label>Search by name</label>                            
                            <input type="text" className="form-control" id="searchQuery" placeholder="Search by name" 
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setNameSearchValue("");
                                        setTimeout(() => {
                                            getCampaigns();
                                        }, 1000);
                                    } else {
                                        setNameSearchValue(e.target.value);
                                    }
                                }}
                                value={nameSearchValue} 
                            />
                        </div>
                        <div className="form-group m-0 template-search-div">
                            <label>Sorting</label>
                            <select className="form-control sort-select" onChange={sortBySelect} value={sortByState}>
                                <option defaultValue="">Sort by</option>
                                <option value="alphabetical">Alphabetical</option>
                                <option value="dateCreated">Date created</option>
                                <option value="lastModified">Last modified</option>
                                <option value="performance">Performance</option>
                            </select>
                        </div>
                        <div className="form-group m-0 template-search-div">  
                            <label>Ordering</label>                          
                            <select className="form-control sort-select" onChange={orderBySelect} value={orderByState}>
                                <option defaultValue="">Order by</option>
                                <option value="ascending">Ascending</option>
                                <option value="descending">Descending</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body no-padding">
                <div className="table-wrap table-responsive">
                    <table className="table m-0">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Comments</th>
                                <th>Views</th>
                                <th>Entries</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                campaigns.length === 0 ? (
                                    <tr>
                                        <td colSpan={7} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    campaigns.map((campaign : CampaignTableRowProps ) => <CampaignTableRow key={campaign.id} {...campaign} campaignResult={campaignResult} dateData={campaignCustomDate}  />)
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
			<div className="card">
				<div className="card-footer clearfix">
					<ul className="pagination pagination-sm m-0 float-right">
						<li className={`${Number(campaignResult.page) === 1 ? "dont-click" : "you-can-click"} page-item`}>
							<span className="page-link" onClick={prev}>
								« Previous
							</span>
						</li>
						<li className={`${campaigns.length !== 0 ? "you-can-click" : "dont-click"} page-item`}>
							<span className="page-link" onClick={next}>
								Next »
							</span>
						</li>
					</ul>
				</div>
			</div>
        </div>
    )
}

export default Campaigns
