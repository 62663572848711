import React/* , { useEffect } */ from 'react';
import { Dropdown } from "react-bootstrap";
import { Trans } from 'react-i18next';
import { NavLink/* , useHistory */ } from 'react-router-dom';
// import '../dashboard/Dashboard.scss';
import '../admin-pages/templates/Templates.scss'
import useAuth from '../auth/useAuth';

export interface TemplateProps {
    previewImage: string,
    name: string,
    publishedAt: string,
    id: string,
    stats: {
        views: number,
        usage: number,
    },
    deleteTemplate: (id: string) => void
}

const TemplateComponent : React.FC<TemplateProps> = ({ previewImage, name, publishedAt, id, stats: { views, usage }, deleteTemplate }) => {
    // const history = useHistory();
    const auth = useAuth();

    const parseISOString = (ISOString: string) : string => {
        let d = ISOString.split(/\D+/);
        let theUtcTime = new Date(Date.UTC(Number(d[0]), /* -- */Number(d[1]), Number(d[2]), Number(d[3]), Number(d[4]), Number(d[5]), Number(d[6])));
        return (
            theUtcTime
                .toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                })
                .replace(/ /g, "/") +
            ", " +
            theUtcTime.toLocaleTimeString()
        );
    };

    // useEffect(() => console.log(history, "history"), [history])


    return (
        <div className="template">
            {
                auth?.adminProfile?.role !== "viewer" && (
                    <Dropdown alignRight className="template-drop-down">
                        <Dropdown.Toggle className="cursor-pointer no-caret template-caret">
                            <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">                        
                            {/* <Dropdown.Item href="!#" onClick={(evt) => evt.preventDefault()} className="preview-item">
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-tooltip-edit text-danger"></i>
                                    </div>
                                </div>
                                <div className="preview-item-content">
                                    <p>Edit</p>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider /> */}
                            <Dropdown.Item href="!#" 
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    deleteTemplate(id);
                                }} 
                                className="preview-item"
                            >
                                <div className="preview-thumbnail">
                                    <div className="preview-icon bg-dark rounded-circle">
                                        <i className="mdi mdi-delete text-danger"></i>
                                    </div>
                                </div>
                                <p className="preview-subject mt-2 ml-3">
                                    <Trans>Delete</Trans>
                                </p>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) 
            }
            <div className="card">
                <NavLink to={`/template/${id}`} className="custom-link">
                    <div className="template-preview-div">
                        <img src={previewImage} alt="uploads" />
                    </div>
                    <div className="card-body template-body">
                        <h6>{name.includes("&amp;") ? name.replace("&amp;", "&") : name}</h6>
                        <small className="text-muted">Published on {parseISOString(publishedAt)}</small>
                    </div>
                </NavLink>
                <NavLink to={`/template/${id}`} className="custom-link">
                    <div className="card-footer template-footer-outer-div">
                        <div className="template-footer-inner-div" style={{ marginBottom: "5px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d81b60" className="bi bi-compass" viewBox="0 0 16 16">
                                <path d="M8 16.016a7.5 7.5 0 0 0 1.962-14.74A1 1 0 0 0 9 0H7a1 1 0 0 0-.962 1.276A7.5 7.5 0 0 0 8 16.016zm6.5-7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                <path d="m6.94 7.44 4.95-2.83-2.83 4.95-4.949 2.83 2.828-4.95z" />
                            </svg>
                            <span className="template-footer-span-label">{views} {" "}Views</span>
                        </div>
                        <div className="template-footer-inner-div">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#d81b60" className="bi bi-people" viewBox="0 0 16 16">
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                            </svg>
                            <span className="template-footer-span-label">{usage} {" "}Usage</span>
                        </div>
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default TemplateComponent
