import React, { useEffect, useReducer } from 'react'
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import PageTab from '../../shared/PageTab'
import { subscriptionsApis } from '../../apis/SubscriptionApi';
import { useDebounce } from 'use-debounce';
import StatsCard from '../../shared/StatsCard';
import AnalyticsTopSection from '../../shared/AnalyticsTopSection';

const initialData = {
  main: {
      stats: {}, 
      dateFrom: "", dateTo: "", userId: "", planId: ""
  },
  loading: true,
  error: ""
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.field]: action.fieldValue };
    case "updateBooleans":
      return { ...state, loading: action.fieldValue };
    case "reset":
      return initialData;
    default:
      return state;
  }
};

function Stats() {
    const tabs = [
        {link: "/subscriptions", text: "Subscriptions"},
        {link: "/subscriptions/stats", text: "Stats"}
]
  
      const [data, setData] = useReducer(reducer, initialData);
      const userId = data.main.userId 
      const PlanId = data.main.planId 

     const [debouncedPlanIdQuery] = useDebounce(PlanId, 1200);
      const [debouncedUserIdQuery] = useDebounce(userId, 1200);
  
      const {stats, ...payLoadParams} = data.main
  
      const getSubsStats = async (payLoad: typeof payLoadParams) => {
          setData({
              type: "update",
              field: "loading",
              fieldValue: true,
            });
          const result = await subscriptionsApis.getSubsStats(payLoad);
          const {status, stats, searchParams} = result
          if(status === "ok"){
          setData({
            type: "update",
            field: "main",
            fieldValue: {stats: stats, ...searchParams},
          });
      } else {
        setData({
          type: "update",
          field: "error",
          fieldValue: result.message,
        });
      }
      setData({ type: "updateBooleans", fieldValue: false });
      }
  
       
      useEffect(() => {
        getSubsStats(payLoadParams)
  
        // eslint-disable-next-line
      }, [
           debouncedUserIdQuery, 
           debouncedPlanIdQuery,
      ])
    
      if (data.loading) {
      return <Roller />
    } else if (data.error === "Invalid Permission") {
          return <NotAuthorized />
      } else if (data.error !== "") {
      return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
        {data.error}
      </div>
    } 
    
    const subsStatsDetails = {
        title: "Subscriptions", 
        items: [{
          name: "Renewals", 
          value: stats.renewCount ?? 0
        },
        {
          name: "Total Amount", 
          value: stats.totalAmountInCents ? `$ ${stats.totalAmountInCents / 100}` : 0
        },
        {
          name: "Total", 
          value: stats.total ?? 0
        },
        ]
      }

      const apiFunction = () => {
        getSubsStats(payLoadParams)
      }
    return (
      <>
      <PageTab tabs={tabs} />
      <div className="card">
              <AnalyticsTopSection 
              data={data}
              setData={setData}
              apiFunction={apiFunction}
              />
  
                  <div className="card-body d-flex justify-content-between flex-wrap my-2">
                    {/* stats card */}
                    <StatsCard 
                    cardDetails={subsStatsDetails}
                    />

              </div>
          </div>
      </>
    )
    
}

export default Stats