import React, { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom';
import PageTab from '../../shared/PageTab'
import { subscriptionsApis } from '../../apis/SubscriptionApi';
import { parseISOString } from '../../shared/parseDate';
import { useDebounce } from 'use-debounce';
import AnalyticsTopSection from '../../shared/AnalyticsTopSection';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import CustomPagination from '../../shared/CustomPagination';

const initialData = {
    main: {
        subscriptions: [], dateFrom: "", dateTo: "", 
        userId: "", planId: "", perPage: 20, page: 1,
    },
    loading: true, 
    error: ""
  };
  
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "update":
        return { ...state, [action.field]: action.fieldValue };
      case "updateBooleans":
        return { ...state, loading: action.fieldValue };
      case "reset":
        return initialData;
      default:
        return state;
    }
  };

function Workflows() {
  const history = useHistory()
    const tabs = [
        {link: "/subscriptions", text: "Subscriptions"},
        {link: "/subscriptions/stats", text: "Stats"}
]

    const [data, setData] = useReducer(reducer, initialData);
    const userId = data.main.userId 
    const planId = data.main.planId 
    const page = data.main.page 
    const perPage = data.main.perPage 

    const [debouncedUserIdQuery] = useDebounce(userId, 1200);
    const [debouncedPlanIdQuery] = useDebounce(planId, 1200);

    const {subscriptions, ...payLoadParams} = data.main

    const getSubscriptions = async (payLoad: typeof payLoadParams) => {
        setData({
            type: "update",
            field: "loading",
            fieldValue: true,
          });
        const result = await subscriptionsApis.getSubscriptions(payLoad);
        if(result.status === "ok"){
        setData({
          type: "update",
          field: "main",
          fieldValue: {...data.main, subscriptions: result.subscriptions, ...result.searchParams},
        });
    } else {
      setData({
        type: "update",
        field: "error",
        fieldValue: result.message,
      });
    }
    setData({ type: "updateBooleans", fieldValue: false });
    }

    const apiFunction = () => {
      getSubscriptions(payLoadParams)
    }
     
    useEffect(() => {
      getSubscriptions(payLoadParams)

      // eslint-disable-next-line
    }, [
        perPage, page,
         debouncedUserIdQuery, debouncedPlanIdQuery,
    ])

    const gotoSubscription = (id: string) => {
        history.push(`/subscriptions/${id}`)
    }
if (data.loading) {
        return <Roller />
      } else if (data.error === "Invalid Permission") {
            return <NotAuthorized />
        } else if (data.error !== "") {
        return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
          {data.error}
        </div>
      } 

  return (
    <>
    <PageTab tabs={tabs} />
    <div className="card">
        <AnalyticsTopSection 
        data={data}
        setData={setData}
        apiFunction={apiFunction}
        />
            
                <div className="card-body no-padding">
                <div className="table-wrap table-responsive">
                <table className="table m-0 table-hover">
                <thead>
                <tr>
                <th>Plan</th>
                <th>Cost</th>
                <th>Active</th>   
                <th>Renewal</th>   
                <th>Cycle</th>    
                <th>Created At</th>
                <th>Starts At</th>  
                <th>Ends At</th>                     
                <th>Renews At</th>   
                <th>Next payment</th>                     
                </tr>
                </thead>
                <tbody>
                {
                data.main.subscriptions.length === 0 ? (
                <tr>
                <td colSpan={6} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                No Data Found
                </td>
                </tr>
                ) : (
                data.main.subscriptions.map((item: any) => (
                <tr key={item.id} className={`you-can-click`} 
                onClick={() => gotoSubscription(item.id)}
                >
                <td className="custom-text-capitalize">{item.planId}</td>
                <td>${item.monthlyCostInCents / 100}</td>
                <td>
                {
                item.isActive ? <span className="badge badge-outline-success">{`Yes`}</span> : <span className="badge badge-outline-warning">{`No`}</span>
                }
                </td>
                <td>
                {
                item.shouldRenew ? <span className="badge badge-outline-success">{`Yes`}</span> : <span className="badge badge-outline-warning">{`No`}</span>
                }
                </td>
                <td>{item.paymentCycle}</td>
                <td>{item.createdAt ? parseISOString(item.createdAt) : "-"}</td>
                <td>{item.startsAt ? parseISOString(item.startsAt) : "-"}</td>
                <td>{item.endsAt ? parseISOString(item.endsAt) : "-"}</td>
                <td>{item.renewsAt ? parseISOString(item.renewsAt) : "-"}</td>
                <td>{item.nextPaymentDueAt ? parseISOString(item.nextPaymentDueAt) : "-"}</td>
                </tr>
                ))
                )
                }

                </tbody>
                </table>
                </div>
            </div>

              <CustomPagination 
              data={data}
              setData={setData}
              apiFunction={() => {}}
              />
            
        </div>
    </>
  )
}

export default Workflows