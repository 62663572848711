import React, { useEffect, useReducer } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import { workflowsApis } from '../../apis/WorkflowApi';
import { parseISOString } from '../../shared/parseDate';
import { useDebounce } from 'use-debounce';
import AnalyticsTopSection from '../../shared/AnalyticsTopSection';
import CustomPagination from '../../shared/CustomPagination';

const initialData = {
    main: {
        instances: [], dateFrom: "", dateTo: "", 
        query: "", perPage: 20, page: 1,
        sortBy: "name", orderBy: "ASC", count: 0
    },
    loading: true,
    error: ""
  };
  
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "update":
        return { ...state, [action.field]: action.fieldValue };
      case "updateBooleans":
        return { ...state, loading: action.fieldValue };
      case "reset":
        return initialData;
      default:
        return state;
    }
  };

  interface UseParamsProps {
    id: string
  }

function WorkflowInstance() {
    const { id } = useParams<UseParamsProps>()
	const history = useHistory();

    const [data, setData] = useReducer(reducer, initialData);
    const userId = data.main.userId 
    const query = data.main.query 
    const perPage = data.main.perPage 
    const page = data.main.page 
    const sortBy = data.main.sortBy 
    const orderBy = data.main.orderBy 

    const [debouncedQuery] = useDebounce(query, 1200);

    const {instances, count, ...payLoadParams} = data.main

    const getInstances = async (payLoad :any, id: string) => {
        setData({
            type: "update",
            field: "loading",
            fieldValue: true,
          });
        const result = await workflowsApis.getWorkflowsInstances(payLoad, id);
        if(result.status === "ok"){
        setData({
          type: "update",
          field: "main",
          fieldValue: {instances: result.instances, ...result.searchParams},
        });
    } else {
      setData({
        type: "update",
        field: "error",
        fieldValue: result.error,
      });
    }
    setData({ type: "updateBooleans", fieldValue: false });
    }
// console.log(data.main)
     
    useEffect(() => {
      getInstances(payLoadParams, id)

      // eslint-disable-next-line
    }, [
         debouncedQuery, userId,
        perPage, page, sortBy, orderBy
    ])

    if (data.loading) {
		return <Roller />
	} else if (data.error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (data.error !== "") {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{data.error}
		</div>
	} 

  const apiFunction = () => {
    getInstances(payLoadParams, id)
  }
  const handleBack = () => {
    history.goBack()
  }

  return (
    <>
    <button className='mx-2 my-1 back-button' onClick={handleBack}>« Back</button>
    <div className="card">
           <AnalyticsTopSection 
           data={data}
           setData={setData}
           apiFunction={apiFunction}
           />
                <div className="card-body no-padding">
                <div className="table-wrap table-responsive">
                <table className="table m-0 table-hover">
                <thead>
                <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>   
                <th>Completed At</th> 
                <th>Started At</th>
                <th>Created At</th>
                <th>Updated At</th>                      
                </tr>
                </thead>
                <tbody>
                {
                data.main.instances.length === 0 ? (
                <tr>
                <td colSpan={8} className="custom-text-capitalize" style={{ textAlign: "center" }}>
                No Data Found
                </td>
                </tr>
                ) : (
                data.main.instances.map((item: any) => (
                <tr key={item.instance.id}
                >
                <td className="custom-text-capitalize">{item.audience.firstName ?`${item.audience.firstName} ${item.audience.lastName}` : "---"}</td>
                <td>{item.audience.email ?? "---"}</td>
                <td>{item.audience.phone ?? "---"}</td>
                <td>
                {
                (item.instance.instanceStatus === "completed") ? <span className="badge badge-outline-success">{item.instance.instanceStatus}</span> : item.instance.instanceStatus === "active" ? <span className="badge" style={{color: "#FF0055", border: "1px solid #FF0055"}}>{item.instance.instanceStatus}</span> : <span className="badge badge-outline-warning">{item.instance.instanceStatus}</span>
                }
                </td>
                <td>{item.instance.completedAt ? parseISOString(item.instance.completedAt) : "---"}</td>
                <td>{item.instance.startedAt ? parseISOString(item.instance.startedAt) : "---"}</td>
                <td>{item.instance.createdAt ? parseISOString(item.instance.createdAt) : "---"}</td>
                <td>{item.instance.updatedAt ? parseISOString(item.instance.updatedAt) : "---"}</td>
                </tr>
                ))
                )
                }

                </tbody>
                </table>
                </div>
            </div>
			<CustomPagination 
      data={data}
      setData={setData}
      apiFunction={()=>{}}
      />
        </div>
    </>
  )
}

export default WorkflowInstance