import React, { useEffect } from 'react'
import useAuth from '../auth/useAuth';
import { useHistory } from 'react-router-dom';

export  interface Props {
data: {
    main: {
        // unique to workflow
        workflows?: [], 
        instances?: [],
        // unique to subscription
        subscriptions?: [],  planId: string,
        stats?: any,
        // others
        dateFrom?: string, dateTo?: string, 
        userId?: string, query?: string, perPage?: number | string, page?: number | string,
        sortBy?: string, status?: string, orderBy?: string, count?: number,
        
    },
    loading: boolean,
    error: string
},
setData: React.Dispatch<any>,
apiFunction: () => void,
}

function AnalyticsTopSection({data, setData, apiFunction}: Props) {
    const dateFrom = data.main.dateFrom
    const dateTo = data.main.dateTo 
    const userId = data.main.userId 
    const query = data.main.query 
    const planId = data.main.planId 
    const sortBy = data.main.sortBy 
    const status = data.main.status 
    const orderBy = data.main.orderBy 
    const isWorkflows = data.main.workflows ? true : false
    const isInstances = data.main.instances ? true : false
    const isSubscriptions = data.main.subscriptions ? true : false
    const isSubscriptionStats = (data.main.stats && data.main.stats.hasOwnProperty("renewCount"))  ? true : false
    const isWorkflowStats = (data.main.stats && data.main.stats.hasOwnProperty("workflowStats"))  ? true : false
    
    const handleDateChange = (e:  React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, [name]: value}
          });
    }

    const useCustomDate = () => {
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, page: 1}
          });
          apiFunction()
    }

    const resetBackToDefault = () => {
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, dateFrom: "", dateTo: "", page: 1}
          });
    }

    const handleSearch = (e:  React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, [name]: value}
          });
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        setData({
            type: "update",
            field: "main",
            fieldValue: {...data.main, [name]: value, page: 1}
          });
    }
    
    let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();

    if (token) {
        token = JSON.parse(token);
    }

    const auth = useAuth();

    useEffect(() => {
        if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

  return (
    <div className="card-header">
            <form 
            className="custom-date-range-form">
                    <h4>Custom Date Range Selection</h4>
                    <div className="input-div">
                        <label htmlFor="dateFrom">Date From</label>
                        <input type="date" className="form-control" name="dateFrom" id="dateFrom" required 
                        value={dateFrom} 
                        onChange={handleDateChange} 
                        />
                    </div>
                    <div className="input-div">
                        <label htmlFor="dateTo">Date To</label>
                        <input type="date" className="form-control" name="dateTo" id="dateTo" required 
                        value={dateTo} 
                        onChange={handleDateChange}
                        />
                    </div>
                    <div className="d-flex justify-content-between custom-btn-div">
                        <button className="btn btn-dark btn-md font-weight-medium"
                         type="submit"
                         disabled={dateFrom === "" || dateTo === ""}
                         onClick={useCustomDate}
                         >
                            Use Custom Date
                        </button>
                        <button className="btn btn-dark btn-md font-weight-medium" type="button" 
                        disabled={dateFrom === "" || dateTo === ""}
                        onClick={resetBackToDefault}
                        >Reset Back To Default</button>
                    </div>
                </form>
                
                <div className="card mb-1">
                    <div className="card-body p-3 d-flex justify-content-between" id="searchBody">  

                    {(isWorkflows || isInstances || isWorkflowStats) &&
                        <>
                        <div className="form-group m-0 template-search-div">
                            <label>Sort by</label>
                            <select className="form-control sort-select" name="sortBy" onChange={handleSelectChange} value={sortBy}>
                                <option value="name">Name</option>
                                <option value="createdAt">Created at</option>
                                <option value="updatedAt">Updated at</option>
                            </select>
                        </div>

                        <div className="form-group m-0 template-search-div">
                            <label>General search</label>                            
                            <input type="text" name="query" className="form-control" id="searchQuery" placeholder="General search" 
                                onChange={handleSearch}
                                value={query} 
                            />
                        </div>
                        </>
                    }

                        {(isSubscriptions || isSubscriptionStats) && <div className="form-group m-0 template-search-div">
                            <label>Search by Plan Id</label>                            
                            <input type="text" name="planId" className="form-control" id="searchQuery" placeholder="Search by Plan Id search" 
                                onChange={handleSearch}
                                value={planId} 
                            />
                        </div>
                        }

                        {!isInstances &&
                            <div className="form-group m-0 template-search-div">
                            <label>Search by User Id</label>                            
                            <input type="text" name="userId" className="form-control" id="searchQuery" placeholder="Search by User Id" 
                                onChange={handleSearch}
                                value={userId} 
                            />
                        </div>}

                        {isWorkflows &&
                            <>
                            <div className="form-group m-0 template-search-div">
                            <label>Status</label>
                            <select className="form-control sort-select" name="status" onChange={handleSelectChange} value={status}>
                                <option value="">All</option>
                                <option value="draft">Draft</option>
                                <option value="paused">Paused</option>
                                <option value="published">Published</option>
                            </select>
                        </div>
                        </>
                            }
                        { (isWorkflows || isInstances || isWorkflowStats) && <>
                        <div className="form-group m-0 template-search-div">  
                            <label>Order By</label>                          
                            <select className="form-control sort-select" name="orderBy" onChange={handleSelectChange} value={orderBy}>
                                <option value="ASC">Ascending</option>
                                <option value="DESC">Descending</option>
                            </select>
                        </div>
                            </>
                        }
                        
                    </div>
                </div>
            </div>
  )
}

export default AnalyticsTopSection