import React, {useEffect, useReducer} from 'react'
import { useParams } from "react-router-dom";
import { subscriptionsApis } from "../../apis/SubscriptionApi"
import { useHistory } from 'react-router-dom';
import useAuth from '../../auth/useAuth';
import NotAuthorized from '../../shared/NotAuthorized';
import Roller from '../../shared/Roller';
import { parseISOString } from '../../shared/parseDate';

interface UseParamsProps {
  id: string
}

const initialData = {
  main: {
      subscription: {}
  },
  loading: true,
  error: ""
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "update":
      return { ...state, [action.field]: action.fieldValue };
    case "updateBooleans":
      return { ...state, loading: action.fieldValue };
    case "reset":
      return initialData;
    default:
      return state;
  }
};

function Workflow() {
  let token = localStorage.getItem("inbrAdminToken");
	const history = useHistory();
    if (token) {
        token = JSON.parse(token);
    }
    const auth = useAuth();
    useEffect(() => {
        if (auth?.adminProfile?.role === "designer") {
			history.push('/templates');
        }
    }, [auth?.adminProfile, history]);

  const { id } = useParams<UseParamsProps>()
  const [data, setData] = useReducer(reducer, initialData);

  const getSubscription = async (id: string) => {
    const result = await subscriptionsApis.getSubscription(id);
    if(result.status === "ok"){
      setData({
        type: "update",
        field: "main",
        fieldValue: {subscription: result.subscription},
      });
  } else {
    setData({
      type: "update",
      field: "error",
      fieldValue: result.message,
    });
  }
  setData({ type: "updateBooleans", fieldValue: false });
  }

  useEffect(() => {
    getSubscription(id)
  }, [id])
  
  if (data.loading) {
		return <Roller />
	} else if (data.error === "Invalid Permission") {
        return <NotAuthorized />
    } else if (data.error !== "") {
		return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "50vh", color: "red"}}>
			{data.error}
		</div>
	} 

  const StatusComponent = (status: boolean, text: string) => {
    return status ? <span className="badge badge-outline-success">{text}</span> : <span className="badge badge-outline-warning">{text}</span>
  }
    const subscription = data.main.subscription

  const subscriptionDetails = [{
      name: "Plan", 
      value: subscription.planId ?? "---"
    },
    {
        name: "cost", 
        value: `$${subscription.monthlyCostInCents / 100}` ?? "---"
      },
      {
        name: "Cycle", 
        value: subscription.paymentCycle ?? "---"
      },
      {
        name: "Active", 
        value: subscription.isActive ? StatusComponent(subscription.isActive, "Yes") : StatusComponent(subscription.isActive, "No")
      },
      {
        name: "Renewal", 
        value: subscription.shouldRenew ? StatusComponent(subscription.shouldRenew, "Yes") : StatusComponent(subscription.shouldRenew, "No")
    },
    {
        name: "Created At", 
        value: subscription.createdAt ? parseISOString(subscription.createdAt) : "---"
    },
    {
        name: "Updated At", 
        value: subscription.updatedAt ? parseISOString(subscription.updatedAt) : "---"
    },
    {
        name: "Last Payment", 
        value: subscription.lastPaymentAt ? parseISOString(subscription.lastPaymentAt) : "---"
      },
    {
        name: "Next Payment", 
        value: subscription.nextPaymentDueAt ? parseISOString(subscription.nextPaymentDueAt) : "---"
    },
    {
        name: "Renews At", 
        value: subscription.renewsAt ? parseISOString(subscription.renewsAt) : "---"
    },
    

    ]

    const handleBack = () => {
      history.push("/subscriptions")
    }


  return (
    <>
      <button className='mx-2 my-1 back-button' onClick={handleBack}>« Back</button>
      <div className='col-12 mt-1 mb-4'>
                 <div className='stats-card'>
                 <div className='d-flex justify-content-between align-items-center mb-3'>
                  <h3>Subscription</h3>
                 </div>
                 <div className='divider mb-4'></div>
                 
                        {subscriptionDetails.map(item => (
                            <div key={item.name} className='d-flex align-items-baseline justify-content-between'><h5 className='text-success col-4'>{item.name}</h5> <p className='col-8'> {item.value}</p></div>
                        ))}
                        <div className='divider mt-4'></div>
                        {<div className='col-12 stats-card-wrapper'>
                 <div className='stats-card' style={{border: "none"}}>
                        <h5 className='mb-4 text-center'>{`Payment dates`}</h5>
                        <div className='d-flex align-items-center justify-content-between'>
                        <h6 className='col-3'>Date</h6> <h6 className='col-3'>Amount</h6> <h6 className='col-3'>Status</h6> <h6 className='col-3'>Id</h6> 
                        </div>
                        {subscription.paymentDates.map((item: any) => (
                            <div key={item.date} className='d-flex align-items-center justify-content-between'>
                                <h6 className='col-3'>{item.date}</h6> 
                                <h6 className='col-3'>${item.amountInCents / 100}</h6> 
                                <h6 className='col-3'>{item.isPaid ? StatusComponent(item.isPaid, "Paid") :  StatusComponent(item.isPaid, "Unpaid")}</h6> 
                                <h6 className='col-3'>{item.paymentId}</h6> 
                                </div>
                        ))}
                 </div>
                 </div>}
                        
                 </div>
                 </div>
    </>
  )
}

export default Workflow