import React from 'react'
import { Link, useLocation } from "react-router-dom";

interface props {
  tabs: {
    link: string
  text: string
}[]
}


function PageTab({tabs}: props) {
  const location = useLocation()

  const isPathActive = (path: string) => {
		return location.pathname === path;
	}

  return (
    <div className='page-tab' style={{flexDirection: "row"}}>
        {
        tabs.map(eachTab => (
            <Link key={eachTab.link} className={`page-tab-link ${isPathActive(eachTab.link) ? "active" : ""}`} to={eachTab.link}>
            {eachTab.text}
        </Link>
        ))
        }
    </div>
  )
}

export default PageTab